import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import classNames from 'classnames';
import LinkModal from '../linkmodal';

class Footer extends Component {

	constructor(props) {
		super(props);		
    }
	
    handleLinkClick = ( linkURL ) => {

        if( this.props.onLinkClick ) this.props.onLinkClick( linkURL );

    }

	render() {

        return (<>
            <section className={style.promoSection}>
                <div className={style.inner}>
                    <div className={style.productHero}>
                        <img src="../../assets/product-hero.png" />
                    </div>
                    <div className={style.promoText}>
                        <h2>READY TO ORDER?</h2>
                        <p>After all that spinning,<br />you must be hungry.</p>
                        <a onClick={ ()=>{ this.handleLinkClick('https://www.wingstop.com/?utm_source=popoutsite&utm_medium=2000store')} } className={classNames('button', 'outline')}>ORDER WINGSTOP NOW</a>
                    </div>
                </div>
            </section>
            <footer class={style['footer']}>
                <div class={style['inner']}>
                    <p class={style['copyright']}>© Wingstop Restaurants Inc. 2023<span className="xs-hidden">&nbsp;&nbsp;|&nbsp;&nbsp;</span><br className="xs-visible" /><a href="/privacy" class={style['privacy']}>Privacy Policy</a></p>
                    <div class={style['social']}>
                        <a onClick={ ()=>{ this.handleLinkClick('https://twitter.com/wingstop')} } ><i class="icon icon-twitter" /></a>
                        <a onClick={ ()=>{ this.handleLinkClick('https://www.facebook.com/Wingstop')} } ><i class="icon icon-facebook" /></a>
                        <a onClick={ ()=>{ this.handleLinkClick('https://www.instagram.com/wingstop/')} } ><i class="icon icon-instagram" /></a>
                        <a onClick={ ()=>{ this.handleLinkClick('https://open.spotify.com/user/r1cb3fd7wt2v3nqjbl5p7p5wx')} } ><i class="icon icon-spotify" /></a>
                    </div>
                </div>
            </footer>
        </>);
    }
};

export default Footer;

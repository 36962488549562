import {Component, createRef} from 'preact';
import { useState } from 'preact/hooks';
import style from './style.css';
import classNames from 'classnames';


class LinkModal extends Component {

	constructor(props) {
		super(props);
	}
	
	hideModal = ( ) => {
		this.props.onClose();
	}

	gotoLink = ( ) => {
		window.open( this.props.linkurl );
		this.hideModal();
	}

	render(props, state) {
		
		return (
			<>
				{ props.isOpen && (
				<div className={classNames(style.linkModal)}>
					<div className={style.linkModalContent}>
						<h2>EXTERNAL SITE</h2>
						<p>You are opening an external site</p>
						<button className={style.buttonBlack} onClick={this.gotoLink}>OK</button>
						<button className={style.buttonWhite} onClick={this.hideModal}>CANCEL</button>
					</div>
				</div> ) }
			</>
		);
	}
	
};


export default LinkModal;

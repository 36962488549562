import { h } from 'preact';
import { Router } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';

import Header from './header';
import Footer from './footer';
import LinkModal from './linkmodal';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import History from '../routes/history';
import All2000Combos from '../routes/all2000combos';
import Privacy from '../routes/privacy';
import ConsentModal from './consent';

const App = () => {
	
	const [isLinkModalOpen, setLinkModalOpen] = useState(false);
	const [linkModalURL , setLinkModalURL ] = useState(false);
	const [showConsentModal , setShowConsentModal ] = useState(false);

	const handleLinkModalClose = () => {
		setLinkModalOpen(false);
	}
	
	const onExternalLinkClicked = ( linkURL ) => {
		setLinkModalURL(linkURL);
		setLinkModalOpen(true);
	};

	const onConsentAccepted = () => {
		window.gtag('config', 'G-4EX7WB0ELS');
	}

	useEffect(() => {
		const trackingConsent = document.cookie
			.split(';')
			.map(cookie => cookie.trim())
			.find(cookie => cookie.startsWith('trackingConsent='))
			?.split('=')[1];

			console.log( document.cookie );
		console.log('consent:'+ trackingConsent);

		if (trackingConsent !== 'accepted') {
			setShowConsentModal(true);
		} else {
			onConsentAccepted();
		}
	}, []);


	return (
		<div id="app">
			{showConsentModal && <ConsentModal onAccept={onConsentAccepted} />}
			<LinkModal isOpen={isLinkModalOpen} onClose={ handleLinkModalClose } linkurl={linkModalURL} />
			<Header onLinkClick={ onExternalLinkClicked } />
			<main>
				<Router>
					<Home path="/" />
					<History path="/history" />
					<All2000Combos path="/all2000combos" />
					<Privacy path="/privacy" />
				</Router>
			</main>
			<Footer onLinkClick={ onExternalLinkClicked } />
		</div>
	);
};

export default App;

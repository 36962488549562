import { h } from 'preact';
import { useState } from 'preact/hooks';
import style from './style.css';
import classNames from 'classnames';

function ConsentModal(props) {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
   
    // Set a cookie or local storage value to indicate that tracking is accepted
    document.cookie = 'trackingConsent=accepted; max-age=31536000; secure; SameSite=None';
   
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        'event': 'trackingConsentGiven'
      });
    }
    setAccepted(true);
    if( props.onAccept ) props.onAccept();
  }

  const handleReject = () => {
    // Set a cookie or local storage value to indicate that tracking is rejected
    document.cookie = 'trackingConsent=rejected; max-age=31536000; secure; SameSite=None';

    // Set the "accepted" state to true to hide the modal
    setAccepted(true);
  }

  if (!accepted) {
    return (
      <div className={classNames(style.modalConsent)}>
        <div className={classNames(style.modalConsentOverlay)}>
            <p>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
            <div className={classNames(style.buttonSet)}>
                <button className={style.btnReject} onClick={handleReject}>Reject All Cookies</button>
                <button className={style.btnAccept} onClick={handleAccept}>Accept All Cookies</button>
            </div>
        </div>
      </div>
    );
  }

  return null;
}

export default ConsentModal;
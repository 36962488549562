import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import classNames from 'classnames';

class Header extends Component {

	constructor(props) {
		super(props);		
    }
	
    handleLinkClick = ( linkURL ) => {
        if( this.props.onLinkClick ) this.props.onLinkClick( linkURL );
    }

	render() {

		return (<>
			<header className={style.header}>
				<div className={style.inner}>
					<a onClick={ ()=>{ this.handleLinkClick( 'https://www.wingstop.com/?utm_source=popoutsite&utm_medium=2000store')}} className={style.logo}>
						<img src="../../assets/wingstop-wt.svg" alt="Wingstop®" />
					</a>
					<nav>
						<Link activeClassName={style.active} href="/" onClick={()=>{
							if (typeof window !== 'undefined' && window.ga) {
								window.ga('send', 'event', 'nav-button', 'click', 'SPIN THE WHEEL');
								}
							}}>
							Spin the Wheel
						</Link>
						<Link activeClassName={style.active} href="/history" onClick={()=>{
							if (typeof window !== 'undefined' && window.ga) {
								window.ga('send', 'event', 'nav-button', 'click', 'HISTORY');
								}
							}}>
							The History
						</Link>
						<Link activeClassName={style.active} href="/all2000combos" onClick={()=>{
							if (typeof window !== 'undefined' && window.ga) {
								window.ga('send', 'event', 'nav-button', 'click', 'ALL 2000 COMBOS');
							}
							}}>
							All 2000 Combos
						</Link>
					</nav>
				</div>
			</header>
		</>);
	};
};

export default Header;
